import { NewsletterPopupStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import styles from "./index.module.css";
import { SubscriptionResult } from "./useSubscribeToNewsletter";
const NewsletterResult = ({
  blok,
  result,
}: {
  blok: NewsletterPopupStoryblok;
  result: SubscriptionResult;
}) => {
  if (!blok) return null;
  const { errorTitle, errorMessage, successTitle, successMessage } = blok;
  return (
    <>
      {!result.subscribed && (
        <>
          {!!errorTitle && (
            <h1 className={clsx(styles.title, styles["text-center"])}>
              {errorTitle}
            </h1>
          )}
          {!!errorMessage && (
            <p
              className={clsx(
                styles["text-center"],
                styles.intro,
                styles.errorMessage,
              )}
            >
              {errorMessage}
            </p>
          )}
        </>
      )}
      {result.subscribed && (
        <>
          {!!successTitle && (
            <h1 className={clsx(styles.title, styles["text-center"])}>
              {successTitle}
            </h1>
          )}
          {!!successMessage && (
            <p
              className={clsx(
                styles["text-center"],
                styles.intro,
                styles.success,
              )}
            >
              {successMessage}
            </p>
          )}
        </>
      )}
    </>
  );
};
export default NewsletterResult;
