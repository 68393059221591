import useLocation from "@/lib/centra/hooks/useLocation";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";
import isEqual from "lodash.isequal";
import { FormEvent, useCallback, useEffect, useState } from "react";
export type SubscriptionResult =
  | {
      subscribed: true;
    }
  | {
      subscribed: false;
      errorMessage: string;
    };
export const useSubscribeToNewsletter = (onSubscribe: () => void) => {
  const token = usePersistedStore((store) => store.token);
  const location = useLocation();
  const [country, setCountry] = useState(location?.country);
  const [firstName, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [result, setResult] = useState<SubscriptionResult | undefined>();
  useEffect(() => {
    if (isEqual(location?.location, country)) {
      return;
    }
    setCountry(location?.location);
  }, [location, country]);
  const subscribeToNewsletter = useCallback(
    async (e: FormEvent): Promise<void> => {
      e.preventDefault();
      const apiUrl = process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;
      const res = await fetch(`${apiUrl}/newsletter-subscription`, {
        method: "POST",
        headers: {
          "API-Token": token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          country: country,
        }),
      });
      if (!res.ok) {
        setResult({ subscribed: false, errorMessage: res.statusText });
        return;
      }
      setResult({ subscribed: true });
      onSubscribe();
    },
    [country, email, token, onSubscribe],
  );
  return {
    result,
    email,
    firstName,
    setEmail,
    setFirstname,
    country,
    subscribeToNewsletter,
  };
};
