"use client";
import clsx from "clsx";
import { ReactNode } from "react";
import styles from "./index.module.css";

export const FooterWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <div className={styles.gradient}></div>
      <footer className={clsx(styles.root, "footer")}>{children}</footer>
    </>
  );
};
