const formatDropdownData = <T extends string, K>(
  data: Record<T, K>[],
  {
    value,
    label,
  }: {
    value: T;
    label: T;
  }
): {
  value: K extends string ? K : string;
  label: K extends string ? K : string;
}[] => {
  return data.map((item) => {
    return {
      value: item[value] as K extends string ? K : string,
      label: item[label] as K extends string ? K : string,
    };
  });
};

export default formatDropdownData;
