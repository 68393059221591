"use client";

import { useQuery } from "@tanstack/react-query";
import { fetchWeatherLocations } from "../actions";
import { FooterWeatherUI } from "./FooterWeatherUI";

export const FooterWeather = () => {
  const { data: windspeed } = useQuery({
    queryKey: ["weather-data"],
    queryFn: () => fetchWeatherLocations()
  });

  if (!windspeed) return null;

  return <FooterWeatherUI windspeed={windspeed} />;
};
