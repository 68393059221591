"use client";
import { useSelection } from "@/lib/centra/hooks/useSelection";
import { NewsletterPopupStoryblok } from "@/lib/types/storyblok-blok-types";
import * as Dialog from "@radix-ui/react-dialog";
import { storyblokEditable, type SbBlokData } from "@storyblok/react/rsc";
import clsx from "clsx";
import { usePathname } from "next/navigation";
import { useCallback, useEffect, useState } from "react";
import NewsletterForm from "./NewsletterForm";
import NewsletterResult from "./NewsletterResult";
import {
  IsNotLoggedInOrSubscribed,
  closedOverXtimes,
  hasClosedAndClosedOverTwoWeeksAgo,
  isOverTwoWeeksAgo,
  userHasNotConfirmedThroughEmail,
} from "./conditions";
import styles from "./index.module.css";
import { useAllTrue } from "./isAllTrue";
import { useSubscribeToNewsletter } from "./useSubscribeToNewsletter";
import { useTimeOut } from "./useTimeout";

type Subscribed = {
  hasSubscribed: boolean;
  closedAt?: string;
  closedCount: number;
};

const defaultSubscribed: Subscribed = {
  hasSubscribed: false,
  closedCount: 0,
  closedAt: "",
};

const setLocalSubscribedState = (subscribed: Subscribed) => {
  localStorage.setItem("newsletterSubscribed", JSON.stringify(subscribed));
};

const getLocalSubscribeState = () => {
  try {
    const newsletterToken = localStorage.getItem("newsletterSubscribed");
    return newsletterToken ? JSON.parse(newsletterToken) : defaultSubscribed;
  } catch (e) {
    return defaultSubscribed;
  }
};

export const NewsLetterPopup = ({
  blok,
}: {
  blok: NewsletterPopupStoryblok;
}) => {
  const pathname = usePathname();
  const twoSecondsPassed = useTimeOut(2);
  const { loggedIn } = useSelection();
  const [subscribedState, setSubscribedState] =
    useState<Subscribed>(defaultSubscribed);
  const [modalOpen, setModalOpen] = useState(true);
  const isConfirmationPageOrSignupPage =
    pathname === "/newsletter-signup-confirmation" ||
    pathname === "/newsletter-signup";

  useEffect(() => {
    setSubscribedState(getLocalSubscribeState());
  }, []);

  // Update local storage hasSubscribed to true when end user
  // is sent to success page from email
  useEffect(() => {
    if (pathname === "/newsletter-signup-success") {
      const updatedSubscribedState = {
        ...subscribedState,
        hasSubscribed: true,
      };
      setSubscribedState(updatedSubscribedState);
      setLocalSubscribedState(updatedSubscribedState);
    }
  }, []);

  const setLocalHasSubscribed = useCallback(
    () => setLocalSubscribedState({ ...subscribedState, hasSubscribed: true }),
    [subscribedState],
  );

  const { result, email, setEmail, country } = useSubscribeToNewsletter(
    setLocalHasSubscribed,
  );

  const conditions = [
    () => userHasNotConfirmedThroughEmail(pathname),
    () =>
      IsNotLoggedInOrSubscribed({
        loggedIn,
        loggedInSubscribed: loggedIn?.newsletter || false,
      }),
    () =>
      hasClosedAndClosedOverTwoWeeksAgo(
        new Date(subscribedState?.closedAt || ""),
        subscribedState?.closedCount,
      ),
    () => closedOverXtimes(subscribedState?.closedCount, 1),
  ];

  const allIsTrue = useAllTrue(conditions);
  const isOpen =
    modalOpen &&
    allIsTrue &&
    !!subscribedState &&
    !subscribedState.hasSubscribed &&
    twoSecondsPassed &&
    !isConfirmationPageOrSignupPage;

  const handleOpenChange = (open: boolean) => {
    setModalOpen(open);
    if (!!open) return;
    const newSubscribeState: Subscribed = {
      ...getLocalSubscribeState(),
      closedAt: new Date().toISOString(),
      closedCount: isOverTwoWeeksAgo(new Date(subscribedState?.closedAt || ""))
        ? 0
        : subscribedState.closedCount + 1,
    };
    setSubscribedState(newSubscribeState);
    setLocalSubscribedState(newSubscribeState);
  };

  return (
    <div data-blok {...storyblokEditable(blok as unknown as SbBlokData)}>
      <Dialog.Root
        open={!!isOpen}
        onOpenChange={(e) => {
          handleOpenChange(e);
        }}
      >
        <Dialog.Overlay className="dialog-overlay" />
        <Dialog.Content
          className={clsx("dialog-content-fade-in", styles.modal)}
        >
          <Dialog.Close className={styles.close}>X</Dialog.Close>
          {!result ? (
            <NewsletterForm
              blok={blok}
              email={email}
              country={country || "en"}
              setEmail={setEmail}
            />
          ) : (
            <NewsletterResult blok={blok} result={result} />
          )}
        </Dialog.Content>
      </Dialog.Root>
    </div>
  );
};
