"use client";

import Button from "@/components/ui/Button";
import { CheckboxField } from "@/components/ui/Checkbox";
import { InputField } from "@/components/ui/Input";
import { useLogin } from "@/lib/centra/hooks/useSelection";
import { useValidation } from "@/lib/centra/hooks/useValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { Suspense } from "react";
import { FormProvider, useForm } from "react-hook-form";
import styles from "../forms.module.css";

export const LoginForm = () => {
  const router = useRouter();
  const validate = useValidation();
  const t = useTranslations();

  const schema = validate.object({
    email: validate.string().email().required(),
    password: validate.string().required(),
    remember: validate.boolean().optional()
  });

  const form = useForm({
    resolver: yupResolver(schema)
  });

  const { handleSubmit } = form;

  const { login } = useLogin();

  const onSubmit = handleSubmit((data) => {
    login.mutate(data, {
      onSuccess: () => {
        router.push("/account");
      }
    });
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className={styles.form}>
        {login.error && (
          <p className={styles.error}>{t("myAccount.loginPage.error")}</p>
        )}
        <div className={styles.inputs}>
          <InputField label="Email" name="email" />
          <InputField label="Password" type="password" name="password" />
        </div>
        <div className={styles.confirmation}>
          <CheckboxField label="Remember me" name="remember" />
          <Link href="/reset-password" className={styles.forgotPassword}>
            Forgot password?
          </Link>
        </div>
        <Button
          className={styles.button}
          isLoading={login.isPending}
          type="submit"
        >
          Login
        </Button>
      </form>
    </FormProvider>
  );
};

export const RegisterCta = () => {
  const t = useTranslations();
  return (
    <section>
      <div className={styles.intro}>
        <h2 className={styles.title}>
          {t("myAccount.accountPage.accountInfo.noAccount")}
        </h2>
        <p className={styles.description}>
          {t("myAccount.accountPage.accountInfo.noAccountText")}
        </p>
      </div>
      <Suspense>
        <RegisterCtaButton />
      </Suspense>
    </section>
  );
};

const RegisterCtaButton = () => {
  return (
    <Button
      className={clsx(styles.registerButton, styles.button)}
      modal={"register"}
    >
      Register
    </Button>
  );
};
