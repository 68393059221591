"use client";
import Button from "@/components/ui/Button";
import { Input } from "@/components/ui/Input";
import { RichTextRender } from "@/components/ui/RichText";
import { NewsletterPopupStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import { useId } from "react";
import styles from "./index.module.css";
const NewsletterForm = ({
  blok,
  email,
  country,
  setEmail,
}: {
  blok: NewsletterPopupStoryblok;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  country: string;
}) => {
  const { title, intro, privacyPolicyText } = blok;
  const t = useTranslations("newsletterPopup");
  const uniqueId = useId();

  return (
    <>
      {title && (
        <h1 className={clsx(styles.title, styles["text-center"])}>{title}</h1>
      )}
      {intro && (
        <p className={clsx(styles["text-center"], styles.intro)}>{intro}</p>
      )}
      <form
        action="https://app.rule.io/subscriber-form/subscriber"
        id={`rule-optin-form${uniqueId}`}
        method="POST"
        className={styles.formgroup}
      >
        <input
          type="hidden"
          name="token"
          value="57d4d5df-c0def74-db60606-2673fb4-f8559f8-c1a"
        />
        <input type="hidden" name="tags[]" value="119963" />
        <input type="hidden" name="language" value="1" />
        <div className={styles.inputgroup}>
          <input type="hidden" name="_country" value={country || ""} />
          <Input
            type="email"
            placeholder="Email"
            id="email"
            name="rule_email"
            required
            value={email || ""}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <Button type="submit" className={clsx("uppercase", styles.submit)}>
          {t("subscribeButton")}
        </Button>
      </form>
      {!!privacyPolicyText && (
        <RichTextRender
          className={styles.policyText}
          blok={privacyPolicyText}
        />
      )}
    </>
  );
};
export default NewsletterForm;
