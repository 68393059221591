import type { OnChangeValue, Options } from "react-select";

import type { DropdownOption } from "../CountryDropdown/UI";
import UI from "./UI";

const selectStyle = () => {
  return {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "#FFFCF9",
      color: state.isSelected ? "#292929" : "#676767",
      fontSize: "16px"
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: "0px"
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#292929",
      padding: "12px 0px",
      margin: "0px"
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      background: "transparent"
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: "#f9f9f2"
    }),
    control: (provided: any) => ({
      ...provided,

      border: "none",
      borderBottom: "1px solid var(--neutral-4)",
      borderRadius: "0px",
      fontSize: "16px",
      backgroundColor: "transparent",
      color: "#292929",
      ":hover": {
        boxShadow: "none",
        borderColor: "#6C6961"
      }
    })
  };
};

interface DropdownProps {
  options: Options<DropdownOption>;
  onChange: (newValue: OnChangeValue<DropdownOption, false>) => void;
  value: string;
  markets?: boolean;
}
const Dropdown = ({
  options,
  onChange,
  value,
  markets = false
}: DropdownProps) => {
  const styles = selectStyle();

  const currentValue =
    options.find((option) => option.value === value) || options[0];

  return (
    <UI
      options={options}
      onChange={onChange}
      styles={styles}
      value={currentValue}
      markets={markets}
    />
  );
};

export default Dropdown;
