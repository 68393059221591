import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgComponent = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="216"
    height="158"
    viewBox="0 0 216 158"
    fill="none"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M81.2585 46.1065L79.3005 44.5276C97.1201 16.9217 138.283 10.0581 168.223 20.7861L173.389 4.43449C102.819 -15.4426 44.1946 35.5515 67.0043 78.1103L65.2216 80.3958C37.7799 64.9238 27.9824 34.4989 52.7501 12.8698L41.338 0.029377C-6.06407 40.1224 40.6219 106.589 108.342 102.371L109.058 104.829C76.4511 118.895 32.7898 108.701 13.3702 85.4858L0 96.394C43.3033 142.817 137.391 131.563 145.764 80.7418L148.438 80.3885C163.759 111.159 137.567 135.427 104.602 140.878L106.918 157.583C176.771 146.328 187.818 75.4643 136.858 49.6104L137.749 47.1447C168.756 51.5426 199.406 71.5855 198.514 100.078H215.618C215.084 35.8976 118.68 18.1329 81.2585 46.0921V46.1065ZM106.918 90.2442C91.056 90.2442 79.4758 82.1549 79.4758 71.0736C79.4758 59.9923 91.056 52.4366 106.918 52.4366C122.779 52.4366 134.535 60.3528 134.535 71.0736C134.535 81.7944 122.772 90.2442 106.918 90.2442Z"
      fill="#161616"
    />
  </svg>
);
export { SvgComponent as LogoSmall };
