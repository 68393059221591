// @ts-nocheck
import { Sketch } from "@p5-wrapper/react";

export const sketch: Sketch = (p5) => {
  try {
    let windValue =
      Number(document.querySelector("#windwrapper").dataset.windvalue) * 4;

    let pg;
    let cloudSize = 6000;
    let cloudShapes = [];
    let windWave;
    let resizeTimeout;
    let visible = false;
    let firstDraw = true;

    const footer = document.querySelector("footer.footer");
    if (!footer) {
      return;
    }

    let observer = new IntersectionObserver((entries) => {
      visible = entries[0]?.isIntersecting;
    });
    observer.observe(footer);
    p5.disableFriendlyErrors = true;

    function gaussianRandom(mean = 0, stdev = 1) {
      const u = 1 - Math.random();
      const v = Math.random();
      const z = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
      return z * stdev + mean;
    }

    function generateCloudShapes() {
      for (let i = 0; i < cloudSize; i++) {
        let x = gaussianRandom(p5.width / 2, p5.width / 9); // Distribute x axis
        let y = gaussianRandom(p5.height / 2, p5.height / 9); // Distribute y axis
        let r = footer.offsetWidth / 600;
        if (r < 1.5) {
          r = 1.5;
        }
        if (r > 3) {
          r = 3;
        }
        cloudShapes.push({ x: x, y: y, radius: r });
      }
    }

    function drawCloud() {
      pg.clear();
      for (let shape of cloudShapes) {
        let noiseValX = p5.noise(
          shape.x * windWave * 0.001 + p5.frameCount * (windWave * 0.001),
          shape.y * 0.01,
        );
        let noiseValY = p5.noise(
          shape.x * windWave * 0.001,
          shape.y * 0.005 + p5.frameCount * (windWave * 0.001),
        );
        let xNoise = p5.map(noiseValX, 0, 1, -windWave * 20, windWave * 20); // Adjust the range as needed
        let yNoise = p5.map(noiseValY, 0, 1, -windWave * 12, windWave * 12);
        pg.rect(shape.x + xNoise, shape.y + yNoise, shape.radius, shape.radius);
      }
    }

    function updateCloudPositions() {
      // Update cloud positions based on Perlin noise
      for (let i = 0; i < cloudShapes.length; i++) {
        let newX = cloudShapes[i].x;
        cloudShapes[i].x = newX;
      }
    }

    p5.setup = () => {
      p5.createCanvas(footer.offsetWidth, footer.offsetHeight);
      pg = p5.createGraphics(p5.width, p5.height);
      pg.fill(181, 173, 143);
      pg.noStroke();
      p5.background(224, 220, 205);
      p5.frameRate(24);
      regenerate();
    };

    function regenerate() {
      let newSeed = p5.floor(Math.random() * 1000);
      p5.noiseSeed(newSeed);
      pg.clear();
      cloudShapes = [];
      generateCloudShapes();
    }

    p5.draw = () => {
      if (!visible && !firstDraw) {
        return;
      }
      firstDraw = false;
      p5.background(224, 220, 205);
      updateCloudPositions();
      drawCloud();
      p5.noStroke();

      //pGraphics (CLOUD)
      p5.image(pg, 0, 0);

      //Map the wind value to a visual value - adjust if needed
      windWave = p5.map(windValue, 0, 30, 2, 12);
    };

    p5.windowResized = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        p5.resizeCanvas(footer.offsetWidth, footer.offsetHeight);
        pg.clear();
        regenerate();
      }, 500);
    };
  } catch (error) {}
};
