"use client";
import Modal from "@/components/ui/Modal";
import { RegisterStoryblok } from "@/lib/types/storyblok-blok-types";
import styles from "../forms.module.css";
import { RegisterForm } from "./RegisterForm";

interface Props {
  intro?: NonNullable<RegisterStoryblok["intro"]>[number];
}

export const RegisterModal = ({ intro }: Props) => {
  return (
    <Modal className={styles.accountModal} modal="register">
      <div className={styles.modal}>
        <div className={styles.intro}>
          <h2>{intro?.title}</h2>
          <p>{intro?.text}</p>
        </div>
        <RegisterForm />
      </div>
    </Modal>
  );
};
