import { useEffect, useState } from "react";
/**
 *
 * @param SECONDS - Expects a number representing seconds.
 * @returns a boolean representing if the timer has passed or not
 */

export const useTimeOut = (SECONDS: number): boolean => {
  const [hasTimePassedState, setHasTimePassedState] = useState(false);
  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setHasTimePassedState(true);
    }, SECONDS * 1000);
    return () => {
      clearTimeout(timeoutID);
    };
  }, [SECONDS]);
  return hasTimePassedState;
};
