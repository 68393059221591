// @ts-nocheck
import ReactCountryFlag from "react-country-flag";
import Select, { components } from "react-select";
import style from "./dropdown.module.css";
const UI = (props) => {
  const { options, onChange, styles, value, markets } = props;

  if (markets) {
    const CustomOption = ({ innerProps, isDisabled, children, value }) => {
      if (isDisabled) {
        return null;
      }
      return (
        <div {...innerProps} className={style.option}>
          <ReactCountryFlag className={style.svg} countryCode={value} svg />
          {children}
        </div>
      );
    };

    const CustomControl = ({ children, ...props }) => {
      return <components.Control {...props}>{children}</components.Control>;
    };

    return (
      <Select
        classNames={{
          container: () => style["container"]
        }}
        components={{ Option: CustomOption, Control: CustomControl }}
        options={options}
        onChange={onChange}
        styles={styles}
        value={value}
      />
    );
  }

  return (
    <Select
      classNames={{
        container: () => style["container"]
      }}
      options={options}
      onChange={onChange}
      styles={styles}
      value={value}
    />
  );
};

export default UI;
