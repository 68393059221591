import { getRequestConfig } from "next-intl/server";

export const DEFAULT_LOCALE = "en";
export const LOCALES = ["en"] as const;
export type Locale = (typeof LOCALES)[number];
export const localePrefix = "as-needed";

export const DEFAULT_TIMEZONE = "Europe/Oslo";

export default getRequestConfig(async ({ locale }) => {
  const messages = await import(`../messages/${locale}.json`);
  return {
    timeZone: DEFAULT_TIMEZONE,
    messages: messages.default || messages
  };
});

export type LocaleProps = {
  params: {
    locale: Locale;
  };
};
