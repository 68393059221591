import { useEffect, useState } from "react";
type Condition = (() => boolean) | boolean;
export const useAllTrue = (conditions: Condition[]) => {
  const [allTrueState, setAllTrueState] = useState(() =>
    conditions.reduce(
      (acc: any, condition: Condition) =>
        acc && (typeof condition === "function" ? condition() : condition),
      true,
    ),
  );
  useEffect(() => {
    const newState = conditions.reduce(
      (acc: any, condition: Condition) =>
        acc && (typeof condition === "function" ? condition() : condition),
      true,
    );
    if (newState !== allTrueState) setAllTrueState(newState);
  }, [allTrueState, conditions]);
  return allTrueState;
};
