import clsx from "clsx";
import { forwardRef, useId } from "react";
import { useFormContext } from "react-hook-form";
import styles from "./index.module.css";

type CheckboxProps = React.ComponentProps<"input"> & {
  label?: React.ReactNode;
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ children, label, className, id, title, required, ...props }, ref) => {
    const internalId = useId();
    const resolvedId = id || internalId;
    return (
      <label htmlFor={resolvedId} className={clsx(styles.root, className)}>
        <span className={styles.indicator} />
        <input
          required={required}
          id={resolvedId}
          type="checkbox"
          title={title}
          {...props}
          ref={ref}
        />
        {label && <span>{label}</span>}
      </label>
    );
  }
);

Checkbox.displayName = "Checkbox";

export const CheckboxField = ({
  name,
  ...props
}: Omit<CheckboxProps, "name"> & { name: string }) => {
  const { register } = useFormContext();
  return <Checkbox {...props} {...register(name)} />;
};
