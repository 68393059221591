"use client";
import Modal from "@/components/ui/Modal";
import { LoginStoryblok } from "@/lib/types/storyblok-blok-types";
import styles from "../forms.module.css";
import { LoginForm, RegisterCta } from "./LoginForm";

interface Props {
  intro?: NonNullable<LoginStoryblok["intro"]>[number];
}

export const LoginModal = ({ intro }: Props) => {
  return (
    <Modal modal="login" className={styles.accountModal}>
      <div className={styles.modal}>
        <div className={styles.intro}>
          <h2>{intro?.title}</h2>
          <p>{intro?.text}</p>
          <LoginForm />
          <RegisterCta />
        </div>
      </div>
    </Modal>
  );
};
