import { useEffect } from "react";
import type { OnChangeValue } from "react-select";

import useChangeLocation from "@/lib/centra/hooks/useChangeLocation";
import useLocation from "@/lib/centra/hooks/useLocation";

import formatDropdownData from "../formatDropdownData";
import type { DropdownOption } from "./UI";
import UI from "./UI";

interface CountryDropdownProps {
  onChange: (e: { target: { value: string } }) => void;
  value: string;
  setValue: (value: string) => void;
}

const CountryDropdown = ({
  onChange,
  value,
  setValue
}: CountryDropdownProps) => {
  const { countries, location } = useLocation();
  const { changeLocation } = useChangeLocation();

  useEffect(() => {
    setValue(location || "NO");
  }, [location, setValue]);
  if (!countries) return null;

  const options = formatDropdownData(countries, {
    value: "country",
    label: "name"
  });

  const ChangeEvent = (e: OnChangeValue<DropdownOption, false>): void => {
    onChange({ target: { value: e?.value || "" } });
    changeLocation.mutate({
      locationId: e?.value || ""
    });
  };

  return <UI options={options} onChange={ChangeEvent} value={value} />;
};

export default CountryDropdown;
