"use client";
import Close from "@/components/icons/Close";
import { LogoSmall } from "@/components/icons/LogoSmall";
import Button from "@/components/ui/Button";
// import modalStyles from "@/components/ui/Modal/index.module.css";
import useModal from "@/hooks/useModal";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import Link from "next/link";
import styles from "./index.module.css";

type ToolbarProps = {
  modal: string;
};

const Toolbar = ({ modal }: ToolbarProps) => {
  const t = useTranslations();
  const { closeModal } = useModal(modal);
  return (
    <div className={clsx(styles.toolBar)}>
      <Link href="/">
        <LogoSmall />
        <span className="sr-only">{t("megaMenu.home")}</span>
      </Link>

      <div>
        <Button onClick={closeModal} rounded className={styles.closeModal}>
          <span className="sr-only">{t("common.close")}</span>
          <Close />
        </Button>
      </div>
    </div>
  );
};
export default Toolbar;
