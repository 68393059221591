"use client";
import { NextReactP5Wrapper } from "@p5-wrapper/next";
import clsx from "clsx";
import { useState } from "react";
import styles from "../index.module.css";
import { sketch } from "./sketch";
import { weatherlocations } from "./weatherLocations";

export const FooterWeatherUI = ({ windspeed }: { windspeed: any[] }) => {
  const [city, setCity] = useState(0);
  const currentWindspeed = windspeed[city]?.wind?.speed;
  return (
    <div className={styles.weatherWrap}>
      <div className={styles.weatherControls}>
        {weatherlocations.map((location, key) => (
          <button
            key={key}
            className={clsx(styles.button, key === city && styles.active)}
            onClick={() => {
              setCity(key);
            }}
          >
            {location} {key === city && `${currentWindspeed} M/S`}
          </button>
        ))}
      </div>
      <div data-windvalue={currentWindspeed} id="windwrapper">
        <NextReactP5Wrapper key={city} sketch={sketch} />
      </div>
    </div>
  );
};
