// @ts-nocheck
"use client";

import Link from "@/components/blocks/atoms/Link";
import { FooterSectionStoryblok } from "@/lib/types/storyblok-blok-types";

import styles from "../index.module.css";

interface IFooterNavigation {
  sections: FooterSectionStoryblok[];
}

const LinkItem = ({ blok }) => {
  if (blok.component === "divider") {
    return <div className={styles.divider} />;
  }
  return <Link blok={blok} />;
};

const RegularSection = ({ section }: { section: FooterSectionStoryblok }) => (
  <div className={styles.col}>
    <nav className={styles.links}>
      {section?.links?.map((linkData) => (
        <LinkItem blok={linkData} key={linkData._uid} />
      ))}
    </nav>
  </div>
);

export const FooterNavigation = ({ sections }: IFooterNavigation) => {
  return (
    <>
      <div className={styles.colwrapper}>
        {sections[0] && <RegularSection section={sections[0]} />}
      </div>
      <div className={styles.doublecolwrapper}>
        {sections[1] && <RegularSection section={sections[1]} />}
        {sections[2] && <RegularSection section={sections[2]} />}
      </div>
    </>
  );
};
