export const IsNotLoggedInOrSubscribed = ({
  loggedIn,
  loggedInSubscribed,
}: {
  loggedIn: any;
  loggedInSubscribed: boolean;
}): boolean => {
  return !loggedIn || (!!loggedIn && !loggedInSubscribed);
};
export const isOverTwoWeeksAgo = (modalLastClosedDate: Date): boolean => {
  const TODAYS_DATE = new Date();
  const TWO_WEEKS = new Date(TODAYS_DATE.setDate(TODAYS_DATE.getDate() - 14));
  const isOlderThanTwoWeeks =
    new Date(modalLastClosedDate).getTime() < TWO_WEEKS.getTime();
  return isOlderThanTwoWeeks;
};
export const hasClosedAndClosedOverTwoWeeksAgo = (
  modalLastClosedDate: Date,
  closedCount: number,
): boolean => {
  return modalLastClosedDate && closedCount >= 3
    ? isOverTwoWeeksAgo(new Date(modalLastClosedDate))
    : true;
};

export const userHasNotConfirmedThroughEmail = (pathname: string): boolean => {
  return pathname !== "/newsletter-signup-success" ? true : false;
};

/** Accepts the count of times closed + max limit of closed counts */
export const closedOverXtimes = (
  closedCount: number,
  maxNumberOfClosed: number,
): boolean => {
  return closedCount < maxNumberOfClosed;
};
